import axios from "axios"

const zendeskApiKey = process.env.GATSBY_ZENDESK_API_KEY
const TEST_ENV = process.env.GATSBY_ENV === "production" ? "" : "[TEST]"

export const zendeskApi = (
  email = process.env.GATSBY_ZENDESK_EMAIL,
  apiUrl,
  apiKey
) => {
  let zendeskKey = email + "/token:" + (apiKey || zendeskApiKey)
  let encryptedKey = Buffer.from(zendeskKey).toString("base64")
  let api = axios.create({
    baseURL: apiUrl || process.env.GATSBY_ZENDESK_API_URL,
    headers: {
      Authorization: "Basic " + encryptedKey,
      "Content-Type": "application/json",
    },
  })

  return api
}

export const zendeskApiEpharmacy = ({
  email = process.env.GATSBY_ZENDESK_EMAIL,
  apiUrl,
  apiKey,
}) => {
  let zendeskKey = email + "/token:" + (apiKey || zendeskApiKey)
  let encryptedKey = Buffer.from(zendeskKey).toString("base64")
  let api = axios.create({
    baseURL: apiUrl || process.env.GATSBY_ZENDESK_API_URL,
    headers: {
      Authorization: "Basic " + encryptedKey,
      "Content-Type": "application/json",
    },
  })

  return api
}

// export const zendeskAPI = zendeskApi()

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ""
  sliceSize = sliceSize || 512
  let byteCharacters = atob(b64Data)
  let byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  let blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const generateUploadFiles = (uploadedFiles) =>
  uploadedFiles.map((response) => {
    return response.data.upload.token
  })

const checkFileSize = (fileArray) => {
  const MAX_SIZE = 10485760
  for (let i = 0; i < fileArray.length; i++) {
    if (fileArray[i].path.size >= MAX_SIZE)
      throw "File is too big to attach in zendesk."
  }
}

export const sendToZendesk = async (
  request,
  fileArray,
  callback,
  zendeskCredentials
) => {
  try {
    const comment = {
      ...request.comment,
      uploads: fileArray?.map((file) => file?.token),
    }
    const requestTemplate = { request: { ...request, comment } }
    return await zendeskApi(
      zendeskCredentials?.apiEmail,
      zendeskCredentials?.apiUrl,
      zendeskCredentials?.apiKey
    ).post("/requests.json", requestTemplate)

    // This is where you will put the confirmation email for users
    // ---------------
    // ---------------
    // if (callback) callback()
  } catch (error) {
    // This is where you will put the  catch functions.
    // such as send email fallback
    // ---------------
    // ---------------
    console.error(error)
  }
}
export const zendeskUploadFiles = (fileArray, callback, zendeskCredentials) =>
  axios.all(
    fileArray.map((file) => {
      const b64Data = file.path.split(",")[1]
      const contentType = file.path.split(",")[0].match(/:(.*?);/)[1]
      return zendeskApi(
        zendeskCredentials?.apiEmail || "",
        zendeskCredentials?.apiUrl || "",
        zendeskCredentials?.apiKey || ""
      ).post(
        `/uploads.json?filename=${file.filename}`,
        b64toBlob(b64Data, contentType),
        { headers: { "Content-Type": "application/binary" } }
      )
    })
  )

export const generateTemplate = (
  subject,
  email,
  template,
  templateObjects,
  tags
) => {
  let ticketFormField = templateObjects
  delete ticketFormField?.files

  if (templateObjects?.scpwdId?.back?.path)
    delete templateObjects?.scpwdId?.back?.path

  if (templateObjects?.scpwdId?.front?.path)
    delete templateObjects?.scpwdId?.front?.path

  return {
    type: "request",
    subject: `${TEST_ENV} ${subject} ${email}`,
    requester: { name: email, email },
    custom_fields: [
      {
        id: process.env.GATSBY_FORM_FIELD_ZENDESK_ID,
        value: JSON.stringify(templateObjects),
      },
    ],
    comment: { body: template(templateObjects) },
    tags,
  }
}
